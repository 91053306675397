.slick {
    &-slider .slick-slide > div {
        font-size: 0!important;
    }
    &-arrow {
    position: absolute;
    z-index: 2;
    top: 50%;
    transform: translate(0, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    padding: 15px;
    background: var(--buttonBgInitial);
    fill: var(--buttonTxtInitial);
    stroke: var(--buttonTxtInitial);
    backdrop-filter: blur(5px);
    transition: ease .3s all;
    border-radius: 0;
    cursor: pointer;
        &:hover {
            background: var(--buttonBgHover);
            fill: var(--buttonTxtHover);
            stroke: var(--buttonTxtHover);
                }
        line {
            stroke-width: 2;
        }
    }
    &-next {
        right: 20px;
    }
    &-prev {
        left: 20px;
        transform: translate(0, -50%) rotate(180deg);
    }
    &-dots {
        display: flex;
        justify-content: center;
        gap: 10px;
        li {
            display: block;
            padding: 0;
            margin: 0;
            font-size: 0;
            &:before {
                display: none;
            }
            &.slick-active button {
                background-color: $color-extra;
            }
            button {
                border-radius: 20px;
                width: 10px;
                height: 10px;
                border: 0;
                background-color: rgba(0,0,0, 0.4);
                padding: 0;
                transition: ease 0.3s all;
                font-size: 0;
                &:hover {
                    background-color: $color-extra;
                }
            }
        }
    }
    &-disabled {
        opacity: 0;
        pointer-events:none;
    }    
}
