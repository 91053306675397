.overlay {
    background: #000;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.3;

    $colors-list: 8;

    @for $i from 1 through $colors-list {
        &[data-overlay*=bg#{$i}]{
            background-color: var(--bg#{$i});
        }
    }

    &[data-overlay*=white] {
        background: #fff;
    }
    &[data-overlay*=blur] {
        backdrop-filter: blur(5px);
        opacity: 1;
        background: rgba(0, 0, 0, 0.5);
        &[data-overlay*=color] {
            background: rgba($color-main, .5);
        }
        &[data-overlay*=color-extra] {
            background: rgba($color-extra, .5);
        }
        &[data-overlay*=color-bg] {
            background: rgba($color-body, .5);
        }
        &[data-overlay*=white] {
            background: rgba(255,255,255, .5);
        }
    }
    &[data-overlay*=o1] {
        opacity: 0.1;
    }
    &[data-overlay*=o2] {
        opacity: 0.2;
    }
    &[data-overlay*=o3] {
        opacity: 0.3;
    }
    &[data-overlay*=o4] {
        opacity: 0.4;
    }
    &[data-overlay*=o5] {
        opacity: 0.5;
    }
    &[data-overlay*=o6] {
        opacity: 0.6;
    }
    &[data-overlay*=o7] {
        opacity: 0.7;
    }
    &[data-overlay*=o8] {
        opacity: 0.8;
    }
    &[data-overlay*=o9] {
        opacity: 0.9;
    }
}
