.subsite {
    //padding-top: 100px;
    .header {
        box-shadow: 1px 1px 20px rgba(48, 58, 114, 0.05);
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        &.sticky {
            position: fixed;
        }
        &__logo--light {
            max-width: 200px;
        }
        // .header__menuItem.login {
        //     position: absolute;
        //     right: unset;
        //     top: 100px;
        //     .header__menuLink {
        //         border: 0;
        //         color: #fff;
        //         background-color: var(--headerTxtHover);
        //         padding: 10px 20px;
        //         svg path {
        //             stroke: #fff;
        //         }
        //     }
        // }
    }
    .historia-spotkan {
        &.faq--separate {
            display: grid;
            grid-template-columns: 100px auto;
            @include mq($until: lg){
                column-gap: 0;
            }
        }
    }
    &__content {
        padding-top: 100px;
        padding-bottom: 100px;
        @include mq($until: md){
            padding-top: 60px;
            padding-bottom: 60px;
        }
        h1 {
            margin-bottom: 40px;
            font-size: rem(31px);
        } 
        @include mq($until: md){
            h1 {
                font-size: rem(24px);
            }
            h2 {
                font-size: rem(23px);
            }
            h3 {
                font-size: rem(21px);
            }
        }
        @include mq($until: xs){
            h1 {
                font-size: rem(22px);
            }
            h2 {
                font-size: rem(20px);
            }
            h3 {
                font-size: rem(18px);
            }
        }
        &.has-sidebar {
            display: grid;
            grid-template-columns: minmax(500px, auto) minmax(300px, 500px);
            column-gap: 100px;
            padding-top: 0;
            margin-top: -100px;
            z-index: 1;
            @include mq($until: xl){
                column-gap: 60px;
            }
            @include mq($until: lg){
                column-gap: 40px;
            }
            @include mq($until: md){
                grid-template-columns: 100%;
                row-gap: 40px;
            }
        }
        .news__content {
            background-color: #fff;
            padding: 40px;
            box-shadow: 1px 1px 20px rgba(48, 58, 114, 0.05);
            @include mq($until: md){
                padding: 30px;
            }
        }
        .sidebar {
            display: flex;
            flex-direction: column;
            row-gap: 40px;
            @include mq($until: md){
                display: none;
            }
            &-block {
                box-shadow: 1px 1px 20px rgba(48, 58, 114, 0.05);
                &__title {
                    font-size: rem(20px);
                    padding: 20px;
                    margin-bottom: 0;
                }
                &--events {
                    background-color: #fff;
                    .events-widget.catalog-list > div[class*='post']:first-of-type .catalog-card {
                        border-top: 1px solid #E8E8E8;
                    }
                    .catalog-card__head {
                    }
                }
            }
        }
    }
}
