.builder-section {
    position: relative;
    display: flex;
    align-items: center;

    &.fullheight {
        min-height: 100vh;
    }

    &.bgcolor {
        background-color: $color-body;
        &--main{
            background-color: $color-main;
        }
        &--extra {
            background-color: $color-extra;
        }
        &--white {
            background-color: #fff;
        }
    }

    &.bgfoto {
        background-size: cover;
        background-position: center;
    }

    &.layout-2 .builder-columns {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        @include mq($until: md) {
            grid-template-columns: 1fr;
        }
    }
    &.layout-3 .builder-columns {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        @include mq($until: xl) {
            grid-template-columns: repeat(2, 1fr);
        }
        @include mq($until: md) {
            grid-template-columns: 1fr;
        }
    }
    &.layout-4 .builder-columns {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        @include mq($until: xl) {
            grid-template-columns: repeat(2, 1fr);
        }
        @include mq($until: md) {
            grid-template-columns: 1fr;
        }
    }
    &.layout-5 .builder-columns {
        display: grid;
        grid-template-columns: 25% 75%;
    }
    &.layout-6 .builder-columns {
        display: grid;
        grid-template-columns: 75% 25%;
    }
    &.padding-top1 {
        padding-top: 20px;
    }
    &.padding-top2 {
        padding-top: 40px;
    }
    &.padding-top3 {
        padding-top: 100px;
    }
    &.padding-top4 {
        padding-top: 180px;
    }
    &.padding-top5 {
        padding-top: 4vw;
    }
    &.padding-top6 {
        padding-top: 8vw;
    }
    &.padding-bottom1 {
        padding-bottom: 20px;
    }
    &.padding-bottom2 {
        padding-bottom: 40px;
    }
    &.padding-bottom3 {
        padding-bottom: 100px;
    }
    &.padding-bottom4 {
        padding-bottom: 180px;
    }
    &.padding-bottom5 {
        padding-bottom: 4vw;
    }
    &.padding-bottom6 {
        padding-bottom: 8vw;
    }
    .builder-columns {
        position: relative;
        z-index: 3;
        gap: 40px;
    }
    .builder-column {
        display: flex;
        flex-direction: column;
        &.align-center {
            justify-content: center;
        }
        &.align-start {
            justify-content: flex-start;
        }
        &.align-end {
            justify-content: flex-end;
        }
        &.justify-center {
            text-align: center;
        }
        &.justify-left {
            text-align: left;
        }
        &.justify-right {
            text-align: right;
        }
        &.text-light, &.text-light p, &.text-light span, &.text-light h1, &.text-light h2, &.text-light h3, &.text-light h4, &.text-light h5, &.text-light h6 {
            color: $color-font-light;
        }
        &.text-dark, &.text-dark p, &.text-dark span, &.text-dark h1, &.text-dark h2, &.text-dark h3, &.text-dark h4, &.text-dark h5, &.text-dark h6 {
            color: $color-font-dark;
        }
    }
}
