.info {
    padding-top: 100px;
    padding-bottom: 100px;
    position: relative;
    @include mq($until: md){
        padding-top: 60px;
        padding-bottom: 60px;
    }
    @include mq($until: xs){
        padding-top: 30px;
        padding-bottom: 30px;
    }
    &--economic {
        padding-bottom: 0;
        img {
            aspect-ratio: 4/3;
            object-fit: cover;
        }
    }
    &--galleries {
        .galeria-list {
            @include mq($until: xl){
                grid-template-columns: repeat(2, 1fr);
            }
            @include mq($until: sm){
                grid-template-columns: 1fr;
            }
            .catalog-card {
                width: 100%;
                &__top {
                    img {
                        aspect-ratio: 5/4;
                        object-fit: cover;
                        @include mq($until: xl){
                            aspect-ratio: 4/3;
                        }
                        @include mq($until: md){
                            aspect-ratio: 1/1;
                        }
                        @include mq($until: sm){
                            aspect-ratio: 16/9;
                        }
                        @include mq($until: 440px){
                            aspect-ratio: 4/3;
                        }
                    }
                }
                &:hover {
                    .catalog-card__content {
                        opacity: 1;
                    }
                    [data-hover*='overlay']:after {
                        opacity: 0.8;
                    }
                }
                &__content {
                    transition: ease 0.3s all;
                    position: absolute;
                    opacity: 0;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                }
                &__head {
                    color: #fff;
                    font-size: rem(20px);
                    @include mq($until: xs){
                        font-size: rem(18px);
                    }
                    @include mq($until: 440px){
                        font-size: rem(16px);
                    }
                }
                &__list span {
                    color: #fff;
                }
                &__footer {
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    border-top: 2px solid rgba(255, 255, 255, 0.3);
                    padding-top: 20px;
                    column-gap: 10px;
                    @include mq($until: md){
                        .catalog-card__more {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    &--news {
    
    }
    &--partners {
        .info__partners {
            margin-bottom: 200px;
            &-title {
                color: var(--paletteColor1);
                font-style: italic;
                text-decoration: underline;
                margin-bottom: 40px;
            }
            .icons-set {
                grid-template-columns: repeat(4, auto);
                column-gap: 40px;
                max-width: 1200px;
                margin: 0 auto;
                @include mq($until: md){
                    grid-template-columns: repeat(2, auto);
                }
                @include mq($until: xs){
                    grid-template-columns: repeat(1, auto);
                }
                &__item {
                    gap: 0;
                    justify-content: center;
                    .content {
                        display: none;
                    }
                    .image {
                        opacity: 0.7;
                        transition: ease 0.3s all;
                        &:hover {
                            opacity: 1;
                        }
                        img {
                            width: auto;
                            filter: grayscale(1) brightness(0.8);
                        }
                    }
                }
            }
        }
    }
    &--offers {
        .info__content {
            position: relative;
            padding-bottom: 1px;
        }
        .all {
            bottom: 30px;
            left: 0;
            position: absolute;
            font-style: italic;
            text-decoration: underline;
            @include mq($until: lg){
                left: unset;
                right: 20px;
                bottom: 40px;
            }
            @include mq($until: md){
                right: unset;
                bottom: unset;
                position: relative;
                text-align: center;
                margin: 0 auto;
                display: block;
            }
        }
    }
    &--membership {
        .info__membership {
            display: grid;
            grid-template-columns: 35% auto;
            align-items: center;
            @include mq($until: lg){
                grid-template-columns: 1fr;
            }
            .join {
                @include mq($until: lg){
                    order: 1;
                    aspect-ratio: 16/9;
                }
                height: 100%;
                //aspect-ratio: 4/3;
                background-size: cover;
                background-position: center;
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;
                padding-top: 30px;
                padding-bottom: 30px;
                padding-left: 70px;
                p {
                    background-color: #000;
                    color: #fff;
                    font-weight: 700;
                    font-size: rem(22px);
                    text-decoration: underline;
                    padding-right: 30px;
                    padding-left: 70px;
                    padding-top: 20px;
                    padding-bottom: 20px;
                    position: relative;
                    @include mq($until: xl){
                        font-size: rem(18px);
                    }
                    img {
                        position: absolute;
                        left: -50px;
                        top: 50%;
                        transform: translateY(-50%);
                        background: #000;
                        border-radius: 100%;
                    }
                }
            }
            .icons-set {
                padding-top: 60px;
                padding-bottom: 60px;
                row-gap: 100px;
                column-gap: 100px;
                @include mq($until: xl){
                    row-gap: 60px;
                    column-gap: 60px;
                }
                &__item {
                    position: relative;
                    overflow: unset;
                    .image {
                        margin-left: -40px;
                        @include mq($until: xl){
                            margin-left: 0;
                        }
                        svg {
                            height: 100px;
                            width: 100px;
                            @include mq($until: xl){
                                height: 60px;
                                width: 60px;
                            }
                        }
                    }
                    &-head {
                        margin-bottom: 40px;
                        column-gap: 40px;
                        @include mq($until: xl){
                            margin-bottom:20px;
                            column-gap: 20px;
                        }
                        @include mq($until: md){
                            flex-direction: column;
                            row-gap: 20px;
                        }
                    }
                    &-text {
                        line-height: rem(32px);
                        @include mq($until: xl){
                            line-height: rem(28px);
                        }
                    }
                    p {
                        font-weight: 700;
                        font-size: rem(21px);
                        @include mq($until: xl){
                            font-size: rem(20px);
                            line-height: rem(20px);
                        }
                    }
                }
            }

        }
        .info__members {
            margin-top: 40px;
            &-title {
                color: var(--paletteColor1);
                font-size: rem(21px);
                text-decoration: underline;
                font-style: italic;
                position: relative;
                z-index: 2;
            }
            .slider {
                // height: 200px;
                margin-top: 10px;
                background-color: #fff;
                padding-top: 60px;
                padding-bottom: 60px;
                box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.1);
                @include mq($until: md){
                    background-color: unset;
                    box-shadow: unset;
                    padding-top: 0;
                    padding-bottom: 0;
                }
                &:after, &:before {
                    content: '';
                    background: linear-gradient(270deg, rgba(255,255,255,0) 0%, var(--bodyBg) 50%, var(--bodyBg) 100%);
                    position: absolute;
                    left: -20px;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 200px;
                    height: 120%;
                    z-index: 1;
                    @include mq($until: md){
                        display: none;
                    }
                }
                &:before {
                    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, var(--bodyBg) 50%, var(--bodyBg) 100%);
                    left: unset;
                    right: -20px;
                }
                .slick-list {
                    height: 100px!important;
                }
                .slick-track {
                    display: flex;
                    align-items: center;
                    //height: 100px;
                }
            }
            img {
                max-width: 300px;
                max-height: 80px;
                height: auto;
                width: auto;
                margin: 0 auto;
                opacity: 0.8;
                transition: ease 0.3s all;
                filter: grayscale(1) brightness(0.8);
                &:hover {
                    opacity: 1;
                }
            }
        }
    }
    &--localization {
        display: grid;
        grid-template-columns: auto 35%;
        align-items: center;
        @include mq($until: md){
            grid-template-columns: 1fr;
            row-gap: 60px;
        }
        .info__map {
            padding: 16px 16px 16px 0;
            background-color: #fff;
            box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.1);
            @include mq($until: md){
                padding: 0;
                box-shadow: unset;
            }
            .leaflet-container {
                height: 80vh!important;
                @include mq($until: md){
                    height: 500px!important;
                }
            }
        }
        .info__content {
            @include mq($until: md){
                text-align: center;    
            }
            h2 {
                text-align: left;
                color: var(--paletteColor1);
                @include mq($until: md){
                    text-align: center;    
                }    
            }
            p {
                line-height: rem(32px);
            }
        }
    }
    &--history {
        .info__history {
            .txtimg {
                display: grid;
                grid-template-columns: 35% auto;
                column-gap: 100px;
                align-items: center;
                @include mq($until: md){
                    grid-template-columns: 1fr;
                    row-gap: 60px;
                }
                &.img-right {
                    .txtimg__content {
                        order: -1;
                    }
                }
                &__content {
                    width: 100%;
                    @include mq($until: md){
                        padding-right: 50px;
                        img {
                            padding-right: 60px;
                        }
                    }
                    @include mq($until: sm){
                        padding-right: 30px;
                    }
                    @include mq($until: xs){
                        padding-right: 20px;
                    }
                    h2 {
                        color: var(--paletteColor1);
                        text-align: right;
                        font-weight: 500;
                        font-style: italic;
                        margin-top: -20px;
                        margin-bottom: 50px;
                        @include mq($until: md){
                            text-align: center;
                        }
                    }
                    p {
                        line-height: rem(32px);
                    }
                }
                &__image, &__slider {
                    width: 100%;
                    padding: 16px 0 16px 16px;
                    background-color: #fff;
                    box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.1);
                    margin-bottom: -300px;
                    @include mq($until: xl){
                        img {
                            aspect-ratio: 5/6;
                            object-fit: cover;
                        }
                    }
                    @include mq($until: lg){
                        img {
                            aspect-ratio: 2/3;
                            object-fit: cover;
                        }
                    }
                    @include mq($until: md){
                        margin-bottom: 0;
                        order: -1;
                        img {
                            aspect-ratio: unset;
                            object-fit: unset;
                        }
                    }
                }
            }
        }
    }
    &__content {
        h2 {
            text-align: center;
            margin-bottom: 80px;
            @include mq($until: xl){
                font-size: rem(28px);
                margin-bottom: 30px;
            }
            @include mq($until: md){
                font-size: rem(24px);
            }
        }
    }
}
