.button {
  color: var(--buttonTxtInitial, $color-font-light);
  padding: 15px 20px;
  transition-property: color, border, background-color;
  transition-duration: .3s;
  transition-timing-function: ease-in-out;
  border: var(--buttonBorder, 0) solid var(--buttonBorderInitial, $color-main);
  background-color: var(--buttonBgInitial, transparent);
  border-radius: var(--buttonRadius, 0);
  display: inline-block;
  line-height: $base-font-size;
  text-align: center;
  align-self: flex-start;
  cursor: pointer;
  text-transform: var(--buttonTxtTransform, initial);
  font-size: $base-font-size;
  &:hover {
    color: var(--buttonTxtHover, $color-font-light);
    border-color: var(--buttonBorderHover, $color-main)
  }

  &--xs {
    font-size: $base-font-size - 6;
    line-height: $base-font-size - 6;
    padding: 5px 20px;
  }
  &--sm {
    font-size: $base-font-size - 3;
    line-height: $base-font-size - 3;
    padding: 10px 20px;
  }
  &--lg {
    font-size: $base-font-size + 3;
    line-height: $base-font-size + 3;
    padding: 20px 20px;
  }
  &--xl {
    font-size: $base-font-size + 6;
    line-height: $base-font-size + 6;
    padding: 25px 20px;
  }

  // &--extra {
  //   background-color: $color-extra;
  //   border-color: $color-extra;
  // }
  // &--black {
  //   background-color: #000;
  //   border-color: #000;
  // }
  // &--white {
  //   background-color: #fff;
  //   border-color: #fff;
  //   color: #000;
  //   &:hover {
  //     color: #000;
  //   }
  // }
  &--shadow {
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  }
  // &--border {
  //   background-color: transparent;
  //   color: $color-main;
  //   border: solid 1px $color-main;
  //   &:hover {
  //     color: $color-main;
  //   }
  // }
  // &--radius {
  //   border-radius: 100px;
  // }
  // &--noradius {
  //   border-radius: 0;
  // }

  // &[data-hover*='white']:hover {
  //   color: $color-font-dark;
  // }

  &[data-hover] {
    &:before, &:after, &[data-hover*="fade"]:hover {
      background-color: var(--buttonBgHover, $color-extra);
    }
  }
  
  &--secondary {
    background-color: var(--button2BgInitial, transparent);
    color: var(--button2TxtInitial, $color-main);
    border: var(--button2Border, 0) solid var(--button2BorderInitial, $color-main);
    border-radius: var(--button2Radius, 0);
    text-transform: var(--button2TxtTransform, initial);
    &:hover {
      color: var(--button2TxtHover, $color-font-light);
      border-color: var(--button2BorderHover, $color-main)
    }
  }
}
