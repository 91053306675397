.news {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    &__title {
        margin: 0;
    }
    &__thumbnail img {
        aspect-ratio: 16/9;
        object-fit: cover;
        width: 100%;
    }
    &__summary {
        display: flex;
        flex-wrap: wrap;
        column-gap: 20px;
        row-gap: 20px;
        &--single {
            padding: 0 20px;
            border-radius: 10px;
            display: flex;
            column-gap: 10px;
        }
        &.block .news__summary--single {
            flex-direction: column;
            align-items: center;
        }
        svg #info {
            fill: var(--icon);
        }
    }
    &__date {
        svg path {
            fill: var(--icon);
        }
    }
    &__phone, &__email, &__website {
        svg path {
            fill: var(--icon);
        }
    }
    &__clock {
        svg path {
            fill: var(--icon);
        }
    }
    &__categories {

        &--single {
            span {
                background-color: var(--paletteColor1);
                padding: 5px 10px;
                border-radius: 5px;
                color: #fff;
                text-transform: uppercase;
                font-size: 13px;
            }
        }
    }
    &__author {

    }
    &__source {

    }
    &__icon {
        svg {
            width: 20px;
            height: auto;
        }
    }
    &__label {
        font-weight: 600;
    }
    .share {
        h2 {
            font-size: rem(20px);
        }
    }
    &.offer {
        .news__thumbnail img {
            aspect-ratio: unset;
        }
    }
    &.companies {
        .news__header {
            display: flex;
            column-gap: 10px;
            row-gap: 20px;
            align-items: center;
            margin-bottom: 20px;
            flex-direction: row-reverse;
            justify-content: space-between;
            @include mq($until: xl){
                flex-direction: column;
                align-items: flex-start;
            }
            img {
                height: auto;
                width: 100%;
                max-width: 160px;
            }
            .news__title {
                margin-bottom: 0;
            }
        }
        .news__summary {
            &--single {
                padding: 0;
                p {
                    margin: 0;
                }
            }
            &.contact .news__summary--single {
                width: 100%;
            }
            // &.contact .news__social svg {
            //     width: 30px;
            //     height: 30px;
            // }
        }
    }
    &.persons {
        .news__group {
            display: grid;
            grid-template-columns: minmax(200px, 300px) minmax(400px, auto);
            column-gap: 40px;
            row-gap: 40px;
            .news__thumbnail img {
                aspect-ratio: unset;
                max-width: 300px;
                margin: 0 auto;
                display: block;            
            }
            @include mq($until: xl){ 
                grid-template-columns: 1fr;
            }
        }
        .news__summary {
            &--single {
                padding: 0;
                p {
                    margin: 0;
                }
            }
            &.contact .news__summary--single {
                width: 100%;
            }
        }
    }
}
