.gallery {
    display: grid;
    grid-gap: 30px;
    font-size: 0;
    &.no-gap {
        grid-gap: 0;
        .gallery__link {
            padding: 0!important;
        }
        .image {
            border-radius: 0;
        }
    }
    .gallery__link {
        transition: transform .3s;
        display: inline-block;
        overflow: hidden;
        background-size: cover;
        background-position: center center;
        position: relative;
        &:hover {
            z-index: 1;
        }
    }
    .image {
        width: 100%;
        border-radius: var(--galleryRadius, 0);
        img {
            width: 100%;
            height: auto;
        }
    }
    &__img {
        width: 100%;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
        transition: box-shadow .3s;
    }
    &.loadmore a {
        display: none;
    }
    [class*='post'] {
        font-size: 0;
    }
    .caption {
        background: var(--cardBg, #fff);
        padding: 10px 20px;
        border-radius: var(--galleryRadius, 0);
        &--above {
            margin-bottom: 10px;
        }
        &--under {
            margin-top: 10px;
        }
        &--left {
            text-align: left;
        }
        &--right {
            text-align: right;
        }
        &--center {
            text-align: center;
        }
        &--hover {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            transition: ease 0.3s all;
            z-index: 1;
            background: transparent;
            display: flex;
            align-items: stretch;
            flex-direction: column;
            justify-content: center;
            &:hover {
                opacity: 1;
            }
            > * {
                color: #fff;
            }
        }
        &--bottom {
            position: absolute;
            bottom: 10px;
            left: 10px;
            z-index: 1;
            background-color: rgba(255, 255, 255, 0.8);
            border-radius: var(--galleryRadius, 0);
            backdrop-filter: blur(10px);
            &.caption--center {
                left: 50%;
                transform: translateX(-50%);
            }
            &.caption--right {
                left: unset;
                right: 0;
            }
        }
        &--top {
            position: absolute;
            top: 10px;
            left: 10px;
            z-index: 1;
            background-color: rgba(255, 255, 255, 0.8);
            border-radius: var(--galleryRadius, 0);
            backdrop-filter: blur(10px);
            &.caption--center {
                left: 50%;
                transform: translateX(-50%);
            }
            &.caption--right {
                left: unset;
                right: 0;
            }
        }
        &__title {
            margin-top: 0;
            margin-bottom: 0;
            font-size: 18px;
            font-weight: 700;
        }
        &__desc {
            margin-top: 10px;
        }
    }
    .icon {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition: ease 0.3s all;
        color: #fff;
        &:hover {
            opacity: 1;
        }
        svg {
            width: 24px;
            height: 24px;
            fill: #fff;
        }
    }
    .imagebg {
        height: 300px;
        background-size: cover;
        background-position: center;
    }

    &.template0 {
        grid-template-columns: 1fr;
    }
    &.template1 {
        grid-template-columns: repeat(2, 1fr);
        @include mq($until: lg) {
            grid-gap: 20px;
        }
        @include mq($until: sm) {
            grid-gap: 10px;
        }
       @include mq($until: 400px) {
            grid-template-columns: 1fr;
        }
    }
    &.template2 {
        grid-template-columns: repeat(3, 1fr);
        @include mq($until: lg) {
            grid-gap: 20px;
        }
        @include mq($until: sm) {
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 10px;
        }
        @include mq($until: 400px) {
            grid-template-columns: 1fr;
        }
    }
    &.template3 {
        grid-template-columns: repeat(4, 1fr);
        @include mq($until: lg) {
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 20px;
        }
        @include mq($until: sm) {
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 10px;
        }
        @include mq($until: 400px) {
            grid-template-columns: 1fr;
        }
    }
    &.template4 {
        grid-template-columns: repeat(5, 1fr);
        @include mq($until: xl) {
            grid-template-columns: repeat(4, 1fr);
        }
        @include mq($until: lg) {
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 20px;
        }
        @include mq($until: sm) {
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 10px;
        }
        @include mq($until: 400px) {
            grid-template-columns: 1fr;
        }
    }

    &.template5 {
        display: flex;
        flex-wrap: wrap;
        margin: 1em -10px 6em -10px;
        gap: 0;
        .gallery__link {
            flex-grow: 1;
            padding: 10px;
        }
        .gallery__link, .image, img {
            width: 100%;
        }
        .gallery__link:nth-child(5n+1),
        .gallery__link:nth-child(5n+2) {
            flex-basis: 50%;
            @include mq($until: 400px) {
                flex-basis: 100%;
            }
        }
        .gallery__link:nth-child(5n+3),
        .gallery__link:nth-child(5n+4),
        .gallery__link:nth-child(5n+5) {
            flex-basis: 33.33%;
            @include mq($until: sm) {
                flex-basis: 50%;
            }
            @include mq($until: 400px) {
                flex-basis: 100%;
            }
        }
    }

    &.template6 {
        display: flex;
        flex-wrap: wrap;
        margin: 1em -10px 6em -10px;
        gap: 0;
        .gallery__link {
            flex-grow: 1;
            padding: 10px;
        }
        .gallery__link, .image, img {
            width: 100%;
        }
        .gallery__link:nth-child(5n+1),
        .gallery__link:nth-child(5n+2),
        .gallery__link:nth-child(5n+3) {
            flex-basis: 33.33%;
            @include mq($until: sm) {
                flex-basis: 50%;
            }
            @include mq($until: 400px) {
                flex-basis: 100%;
            }
        }
        .gallery__link:nth-child(5n+4),
        .gallery__link:nth-child(5n+5) {
            flex-basis: 50%;
            @include mq($until: 400px) {
                flex-basis: 100%;
            }
        }
    }

    &.template7 {
        display: flex;
        flex-wrap: wrap;
        margin: 1em -10px 6em -10px;
        gap: 0;
        .gallery__link {
            flex-grow: 1;
            padding: 10px;
        }
        .gallery__link, .image, img {
            width: 100%;
        }
        .gallery__link:nth-child(6n+1),
        .gallery__link:nth-child(6n+6) {
            flex-basis: 50%;
            @include mq($until: lg) {
                flex-basis: 33.33%;
            }
            @include mq($until: sm) {
                flex-basis: 50%;
            }
            @include mq($until: 400px) {
                flex-basis: 100%;
            }
        }
        .gallery__link:nth-child(6n+2),
        .gallery__link:nth-child(6n+3),
        .gallery__link:nth-child(6n+4),
        .gallery__link:nth-child(6n+5) {
            flex-basis: 25%;
            @include mq($until: lg) {
                flex-basis: 33.33%;
            }    
            @include mq($until: sm) {
                flex-basis: 50%;
            }
            @include mq($until: 400px) {
                flex-basis: 100%;
            }
        }
    }
    &.template8 {
        display: flex;
        flex-wrap: wrap;
        margin: 1em -10px 6em -10px;
        gap: 0;
        .gallery__link {
            flex-grow: 1;
            padding: 10px;
        }
        .gallery__link, .image, img {
            width: 100%;
        }
        .gallery__link:nth-child(6n+1),
        .gallery__link:nth-child(6n+2),
        .gallery__link:nth-child(6n+5),
        .gallery__link:nth-child(6n+6) {
            flex-basis: 25%;
            @include mq($until: lg) {
                flex-basis: 33.33%;
            }
            @include mq($until: sm) {
                flex-basis: 50%;
            }
            @include mq($until: 400px) {
                flex-basis: 100%;
            }
        }
        .gallery__link:nth-child(6n+3),
        .gallery__link:nth-child(6n+4) {
            flex-basis: 50%;
            @include mq($until: lg) {
                flex-basis: 33.33%;
            }
            @include mq($until: sm) {
                flex-basis: 50%;
            }
            @include mq($until: 400px) {
                flex-basis: 100%;
            }
        }
    }

    &.template9 {
        display: flex;
        flex-wrap: wrap;
        margin: 1em -10px 6em -10px;
        gap: 0;
        .gallery__link {
            flex-grow: 1;
            padding: 10px;
        }
        .gallery__link, .image, img {
            width: 100%;
        }
        .gallery__link:nth-child(6n+1),
        .gallery__link:nth-child(6n+2),
        .gallery__link:nth-child(6n+3),
        .gallery__link:nth-child(6n+4) {
            flex-basis: 25%;
            @include mq($until: lg) {
                flex-basis: 33.33%;
            }
        }
        .gallery__link:nth-child(6n+5),
        .gallery__link:nth-child(6n+6) {
            flex-basis: 50%;
            @include mq($until: lg) {
                flex-basis: 33.33%;
            }
            @include mq($until: sm) {
                flex-basis: 50%;
            }
            @include mq($until: 400px) {
                flex-basis: 100%;
            }
        }
    }
    &.template10 {
        display: flex;
        flex-wrap: wrap;
        margin: 1em -10px 6em -10px;
        gap: 0;
        .gallery__link {
            flex-grow: 1;
            padding: 10px;
        }
        .gallery__link, .image, img {
            width: 100%;
        }
        .gallery__link:nth-child(6n+1),
        .gallery__link:nth-child(6n+2){
            flex-basis: 50%;
            @include mq($until: lg) {
                flex-basis: 33.33%;
            }
            @include mq($until: sm) {
                flex-basis: 50%;
            }
            @include mq($until: 400px) {
                flex-basis: 100%;
            }
        }
        .gallery__link:nth-child(6n+3),
        .gallery__link:nth-child(6n+4),
        .gallery__link:nth-child(6n+5),
        .gallery__link:nth-child(6n+6) {
            flex-basis: 25%;
            @include mq($until: lg) {
                flex-basis: 33.33%;
            }
            @include mq($until: sm) {
                flex-basis: 50%;
            }
            @include mq($until: 400px) {
                flex-basis: 100%;
            }
        }
    }

    &.template11 {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));
        grid-auto-rows: 400px;
        grid-auto-flow: row dense;
        gap: 0;
        @include mq($until: lg) {
            grid-auto-rows: 300px;
        }
        @include mq($until: sm) {
            grid-auto-rows: 180px;
        }
        @include mq($until: 460px) {
            grid-template-columns: 1fr;
            grid-auto-rows: 250px;
        }

        .gallery__link {
            padding: 10px;
        }
        .image, .imagebg, [class*='post'] {
            width: 100%;
            height: 100%;
        }
        .gallery__link:nth-child(6n+1){
            grid-row: auto / span 2;
            grid-column: auto / span 1;
            @include mq($until: 460px) {
                grid-row: auto / span 1;
            }
        }
        .gallery__link:nth-child(6n+5){
            grid-row: auto / span 2;
            grid-column: auto / span 1;
            @include mq($until: 460px) {
                grid-row: auto / span 1;
            }
        }
    }
    &.template12 {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
        grid-auto-rows: 400px;
        grid-auto-flow: row dense;
        gap: 0;
        @include mq($until: xl) {
            grid-auto-rows: 300px;
        }
        @include mq($until: lg) {
            grid-template-columns: repeat(auto-fit, minmax(33.33%, 1fr));
        }
        @include mq($until: md) {
            grid-auto-rows: 240px;
        }
        @include mq($until: sm) {
            grid-auto-rows: 180px;
            grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));
        }
        @include mq($until: 460px) {
            grid-template-columns: 1fr;
            grid-auto-rows: 250px;
        }

        .gallery__link {
            padding: 10px;
        }
        .image, .imagebg, [class*='post'] {
            width: 100%;
            height: 100%;
        }
        .gallery__link:nth-child(7n+1){
            grid-row: auto / span 2;
            grid-column: auto / span 2;
            @include mq($until: 460px) {
                grid-row: auto / span 1;
                grid-column: auto / span 1;
            }
        }
    }
    &.template13 {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
        grid-auto-rows: 300px;
        grid-auto-flow: row dense;
        gap: 0;
        @include mq($until: lg) {
            grid-template-columns: repeat(auto-fit, minmax(33.33%, 1fr));
        }
        @include mq($until: md) {
            grid-auto-rows: 240px;
        }
        @include mq($until: sm) {
            grid-auto-rows: 180px;
            grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));
        }
        @include mq($until: 460px) {
            grid-template-columns: 1fr;
            grid-auto-rows: 250px;
        }
        .gallery__link {
            padding: 10px;
        }
        .image, .imagebg, [class*='post'] {
            width: 100%;
            height: 100%;
        }
        .gallery__link:nth-child(2n+1){
            grid-row: auto / span 2;
            @include mq($until: 460px) {
                grid-row: auto / span 1;
            }
        }
        @include mq($until: lg) {
            .gallery__link:nth-child(3n+1){
                grid-row: auto / span 2;
            }
        }
        @include mq($until: 460px) {
            .gallery__link:nth-child(3n+1){
                grid-row: auto / span 1;
            }
        }

    }
}

.loadmore {
    margin-top: 40px;
}
