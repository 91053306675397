.pagination {
  margin: 40px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  &.left {
    justify-content: flex-start;
  }
  &.right {
    justify-content: flex-end;
  }
  &__item {
    margin: 0;
    padding: 0;
    &:before {
      display: none;
    }
  }
  &__link {
    font-weight: $fw-bold;
    font-size: rem(20px);
    color:  var(--txt);
    background-color: #fff;
    padding: 10px;
    width: 46px;
    display: block;
    text-align: center;
    transition: ease 0.3s all;
    &:hover, &--active {
      color: #fff;
      background-color:  var(--paletteColor1);
      svg line {stroke: #fff;}
    }
    &--disabled {
      cursor: context-menu !important;
      &:hover {
        color: var(--txt);
      }
    }
  }
  &__next {
    svg {
      transform: rotate(270deg);
      width: 20px;
    }
    svg line{stroke: var(--txt); stroke-width: 2;}
  }
  &__prev {
    svg {
      transform: rotate(90deg);
      width: 20px;
    }
    svg line{stroke: var(--txt); stroke-width: 2;}
  }
}
