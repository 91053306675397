.catalog-map {
    margin-bottom: 100px;
    .map__content {
        height: 80vh;
    }
    .map__popup {
        &-logo {
            height: 200px;
            background-position: center;
            background-repeat: no-repeat;
            background-color: $color-main;
            border-radius: 12px 12px 0 0;
        }
        &-image {
            height: 200px;
            background-position: center;
            background-repeat: no-repeat;
            background-color: $color-main;
            background-size: cover;
            border-radius: 12px 12px 0 0;
        }
        &-address {
            font-size: 14px;
        }
        &-content {
            padding: 15px;
        }
        h4 {
            font-size: 18px;
        }
        .button {
            color: #fff;
            display: block;
            margin-top: 20px;
        }
    }
    .leaflet-popup-content {
        margin: 0;
    }
}

.catalog-list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 40px;
    @include mq($until: xl){
        gap: 30px;
    }
    @include mq($until: lg){
        .catalog-card__top .catalog-card__price {
            top: 20px;
        }
    }
    &--col2 {
        grid-template-columns: repeat(2, 1fr);
        @include mq($until: xxl){
            &.image-left, &.image-right {
                grid-template-columns: 1fr;
                max-width: 1200px;
                margin: 0 auto;
                .catalog-card.image-left {
                    grid-template-columns: 40% 1fr;
                }
                .catalog-card.image-right {
                    grid-template-columns: 1fr 40%;
                }
            }
        }
        @include mq($until: md){
            &.image-left .catalog-card.image-left, &.image-right .catalog-card.image-right {
                grid-template-columns: 1fr;
            }
            .catalog-card.image-right .catalog-card__top {
                order: 0;
            }            
        }
        @include mq($until: xs){
            grid-template-columns: 1fr;
        }
    }
    &--col3 {
        grid-template-columns: repeat(3, 1fr);
        @include mq($until: md){
            grid-template-columns: repeat(2, 1fr);
        }
        @include mq($until: xs){
            grid-template-columns: 1fr;
        }
    }
    &--col4 {
        grid-template-columns: repeat(4, 1fr);
        @include mq($until: xl){
            grid-template-columns: repeat(3, 1fr);
        }
        @include mq($until: md){
            grid-template-columns: repeat(2, 1fr);
        }
        @include mq($until: xs){
            grid-template-columns: 1fr;
        }
    }
    &--col5 {
        grid-template-columns: repeat(5, 1fr);
        @include mq($until: xxl){
            grid-template-columns: repeat(4, 1fr);
        }
        @include mq($until: xl){
            grid-template-columns: repeat(3, 1fr);
        }
        @include mq($until: md){
            grid-template-columns: repeat(2, 1fr);
        }
        @include mq($until: xs){
            grid-template-columns: 1fr;
        }
    }
    &.slick-slider {
        display: block;
        // .slick-arrow {
        //     top: -70px;
        //     right: 60px;
        //     left: unset;
        //     background: #fff;
        //     &:hover {
        //         background: $color-extra;
        //     }
        //     &:last-child {
        //         right: 0;
        //     }
        // }
        .slick-track {
            display: flex;
            gap: 30px;
        }
        .slick-slide {
            display: flex;
            height: auto;
        }
        .catalog-card {
            display: flex!important;
            height: 100%;
        }
        .catalog-card.image-left,
        .catalog-card.image-right {
            display: grid!important;
        }        
    }
    &.image-left.slick-slider,
    &.image-right.slick-slider {
        .slick-track {
            gap: 40px;
            @include mq($until: md){
                gap: 0;
            }
        }
        .slick-list {
            //margin-left: -20px;
            //margin-right: -10px;        
            margin-left: -29px;
            margin-right: -19px;
            @include mq($until: md){
                margin-left: 0;
                margin-right: 0;
            }
        }
        .slick-slide {
            display: block;
        }
    }
    & > div[class*='post'] {
        display: flex;
    }
    &.news-categories-list {
        .catalog-card__top {
            text-align: center;
        }
        .catalog-card.image-left {
            grid-template-columns: auto 1fr;
        }
        .catalog-card.image-right {
            grid-template-columns: 1fr auto;
        }
    }
    &.galeria-list {
        .catalog-card__footer {
            row-gap: 20px;
        }
    }
    &.companies-list {
        .catalog-card {
            justify-content: center;
            align-items: center;
            &__content {
                flex: unset;
                justify-content: center;
                text-align: center;
            }
            &__image {
                margin-top: 20px;
                max-width: 200px;
                max-height: 100px;
                height: auto;
                width: auto;
            }
        }
    }
}
.catalog-more {
    margin-top: 40px;
    text-align: center;
}
