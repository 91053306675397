.svg-icon{
  transition: fill .3s ease-in-out;
  @include mq($until: xs){
    max-width: 25px;
  }
}
.contact-data {
  display: grid;
  grid-template-columns:1fr;
  grid-gap: 40px;

  &.column {
    &2 {
      grid-template-columns: 1fr 1fr;
      @include mq($until: sm){
        grid-template-columns: 1fr;
      }
    }
    &3 {
      grid-template-columns: 1fr 1fr 1fr;
      @include mq($until: md){
        grid-template-columns: 1fr 1fr;
      }
      @include mq($until: sm){
        grid-template-columns: 1fr;
      }
    }
    &4 {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      @include mq($until: lg){
        grid-template-columns: 1fr 1fr 1fr;
      }
      @include mq($until: md){
        grid-template-columns: 1fr 1fr;
      }
      @include mq($until: sm){
        grid-template-columns: 1fr;
      }
    }
  }
  &__group {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  &__section{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    p{
      margin-bottom: 0;
    }
    &--name{
      display: block;
      p{
        font-size: rem(20px);
        font-weight: $fw-semi-bold;
      }
    }
    &--social .svg-icon{
      max-width: unset;
      margin-right: 5px;
      fill: var(--icon, $color-main);
      @include mq($until: xs){
          max-width: 35px;
      }
    }
    &--social a:hover .svg-icon{
        fill: var(--linkHover, $color-extra);
    }
  }

  &__icon{
    margin-right: 15px;
    svg {
      fill: var(--icon, $color-main);
      width: 30px;
      height: 30px;
    }
  }
  &__content{
    &--phones, &--emails{
      display: flex;
      flex-direction: column;
    }
    a {
      color: var(--txt, $paragraph-color);
      &:hover {
        color: var(--linkHover, darken($link-color, 20%));
      }
    }
  }
}
