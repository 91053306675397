.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  min-height: 100px;
  background-color: var(--headerBg);
  z-index: 1001;

  &--absolute {
    position: absolute;
    width: 100%;
    border-bottom: none;
    background: var(--headerAbsoluteBg, transparent);
    padding-top: 60px;
    .header__logo--dark {
      display: none;
    }
    .header__menuLink {
      color: var(--headerAbsoluteTxtInitial);
      &:active, &--active, &--open, &:hover {
        color: var(--headerAbsoluteTxtHover);
      }
    }
    .header__submenu .header__menuLink {
      color: #fff;
      &:active, &--active, &--open, &:hover {
        color: #fff;
      }
    }
  }

  &--fixed {
    transition: background-color .3s ease-in-out, padding-top .3s ease-in-out;
    &.sticky {
      position: fixed;
      width: 100%;
      box-shadow: 1px 1px 20px rgba(48, 58, 114, 0.05);
      background-color: var(--headerBg);
      align-items: center;
      padding-top: 0;
      .header__logo--dark {
        display: block;
      }
      .header__logo--light {
        display: none;
      }
      .header__menuLink {
        color: var(--headerTxtInitial);
        &:active, &--active, &--open, &:hover {
          color: var(--headerTxtHover);
        }
      }
      .header__submenu .header__menuLink {
        color: #fff;
        &:active, &--active, &--open, &:hover {
          color: #fff;
        }
      }
      .header__menuItem.login {
        // position: relative;
        right: unset;
        // top: unset;
        top: 100px;
        .header__menuLink {
          border: 0;
          color: #fff;
          background-color: var(--headerTxtHover);
          padding: 10px 20px;
          &:hover {
            background-color: var(--buttonBgHover);
          }
          svg path {
            stroke: #fff;
          }
        }
      }
    }
  }

  &__logo {
    position: relative;
    z-index: 2;
    // &--dark {
    //   display: none;
    // }
    img {
      height: auto;
      min-width: 170px;
      @include mq($until: lg) {
        width: 13vw;
        min-width: 170px;
      }
      @include mq($until: md) {
        width: 170px;
        min-width: 140px;
      }
    }
  }

  &__nav {
    display: flex;
    align-items: center;
    column-gap: 30px;
    &--fullscreen {
      width: 100%;
      height: 100vh;
      background-color: $color-extra;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      padding-top: 100px;
      transform: translate(0, -100%);
      transition: transform 0.3s ease-in-out;
    }
    &--visible {
      transform: translate(0, 0);
    }
  }

  &__menuList {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
    @include mq($until: xl) {
      flex-wrap: wrap;
    }
    &--fullscreen {
      flex-direction: column;
    }
  }

  &__menuItem {
    margin: 0;
    padding: 0;
    &--fullscreen{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
    }
    &::before {
      display: none;
    }
    &.login {
      position: absolute;
      top: 0;
      right: 8vw;
      .header__menuLink {
        display: flex;
        align-items: center;
        column-gap: 10px;
        padding: 5px 10px 5px 10px;
        border-left: 1px solid rgba(255, 255, 255, 0.4);
        border-right: 1px solid rgba(255, 255, 255, 0.4);
        border-bottom: 1px solid rgba(255, 255, 255, 0.4);
        opacity: 1;
        &:hover {
          border-color: var(--headerTxtHover);
        }
      }
    }
  }

  &__menuLink {
    color: var(--headerTxtInitial);
    padding: 20px 15px;
    display: block;
    font-weight: 500;
    font-size: rem(16px);
    border-radius: var(--menuRadius, 0);
    text-transform: uppercase;
    opacity: 0.6;
    transition: ease 0.3s all;
    white-space: nowrap;
    @include mq($until: xl) {
      padding: 10px 10px;
      white-space: normal;
    }    
    @include mq($until: lg) {
    }    
    &--fullscreen {
      color: #ffffff;
      font-size: rem(28px);
    }
    &:active, &--active, &--open, &:hover {
      color:var(--headerTxtHover);
      opacity: 1;
    }
  }
}
