.events-widget {
    &--title {
        margin-bottom: 20px;
        font-weight: 600;
    }
    &.catalog-list {
        box-shadow: 1px 1px 20px rgba(48, 58, 114, 0.05);
        gap: 0;
        border-radius: var(--cardRadius, 0);
        background: var(--cardBg, #fff);
        &--boxed .catalog-card {
            box-shadow: unset;
            border-radius: 0;
            background: unset;
            border-top: 1px solid #E8E8E8;
            padding: 15px;
            &__content {
                padding: 14px 20px;
                row-gap: 10px;
                justify-content: center;
            }
            &:hover .events-widget__content {
                background-color: rgba(232, 232, 232, 0.5);
            }
        }
        & > div[class*='post']:first-of-type .catalog-card {
            border-top: unset;
        }
    }
    .catalog-card__list {
        flex-direction: row;
        row-gap: 5px;
        column-gap: 10px;
        flex-wrap: wrap;
    }
    &__content {
        display: grid;
        grid-template-columns: 110px auto;
        //align-items: start;
        transition: ease 0.3s all;
        @include mq($until: sm){
            grid-template-columns: 80px auto;
        }
        @include mq($until: xs){
            grid-template-columns: 1fr;
        }
}
    &__day {
        border: 1px solid #E8E8E8;
        background-color: #fff;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 15px;
        justify-content: center;
        .day {
            font-size: 30px;
            font-weight: 700;
            @include mq($until: sm){
                font-size: 24px;
            }
        }
    }
}
