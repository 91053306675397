.videos {
    padding-top: 100px;
    padding-bottom: 100px;
    position: relative;
    background-color: var(--paletteColor7);
    @include mq($until: md){
        padding-top: 60px;
        padding-bottom: 60px;
    }
    @include mq($until: xs){
        padding-top: 40px;
        padding-bottom: 30px;
    }
    &__top {
        display: grid;
        grid-template-columns: 35% auto;
        column-gap: 100px;
        align-items: center;
        @include mq($until: md){
            grid-template-columns: 1fr;
        }
        &-content {
            @include mq($until: md){
                text-align: center;
            }
            h2 {
                color: var(--paletteColor1);
                text-transform: uppercase;
                margin-bottom: 80px;
                @include mq($until: xl){
                    font-size: rem(28px);
                    margin-bottom: 30px;
                }
                @include mq($until: md){
                    font-size: rem(24px);
                }
            }
            p {
                color: #fff;
                line-height: rem(38px);
                @include mq($until: xl){
                    line-height: rem(24px);
                }    
            }
        }
        &-player {
            @include mq($until: md){
                margin-top: 40px;
            }
            img {
                width: 100%;
                aspect-ratio: 16/9;
                object-fit: cover;
            }
        }
    }
    &__bottom {
        margin-top: 70px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 50px;
        row-gap: 20px;
        @include mq($until: xl){
            grid-template-columns: repeat(2, 1fr);
        }
        @include mq($until: md){
            margin-top: 20px;
        }
        @include mq($until: xs){
            grid-template-columns: 1fr;
        }
    }
}
