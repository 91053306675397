.hero {
    position: relative;
    background-position: left center;
    background-repeat: no-repeat;
    background-color: #000;
    padding-top: 20px;
    padding-bottom: 20px;
    height: 320px;
    display: flex;
    align-items: center;
    @include mq($until: md){
        height: 220px;
    }
    &--small {
        min-height: 320px;
        height: unset;
        padding-top: 250px;
        padding-bottom: 100px;
        @include mq($until: md){
            display: flex;
            padding-top: 120px;
            min-height: 220px;
            padding-bottom: 60px;
        }
        .hero__content {
            max-width: unset;
        }
        @include mq($until: lg){
            .hero__content {
                h1 {
                    font-size: rem(38px);
                }
            }
        }
        @include mq($until: md){
            .hero__content {
                h1 {
                    font-size: rem(28px);
                    font-weight: 600;
                }
            }
        }
    }
    &__content {
        position: relative;
        z-index: 1;
        max-width: 600px;
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        align-items: flex-start;
        h1 {
            color: #fff;
            margin-bottom: 0;
            font-size: rem(42px);
            font-weight: 700;
        }
        h2 {
            color: #fff;
            margin-top: 0;
            margin-bottom: 0;
            font-size: rem(24px);
            font-weight: 700;
        }
        p {
            color: #fff;
            &:first-of-type {
                margin-top: 20px;
            }
        }
        a {
            display: block;
            align-self: unset;
            margin-top: 20px;
        }
        &--center {
            text-align: center;
            align-items: center;
            margin: 0 auto;
        }
        &--right {
            margin: 0 0 0 auto;
            text-align: right;
            align-items: flex-end;
        }
    }
}
