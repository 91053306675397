.featured {
    padding-bottom: 100px;
    position: relative;
    @include mq($until: md){
        padding: 0;
    }
    &__top {
        display: flex;
        flex-wrap: nowrap;
        row-gap: 60px;
        @include mq($until: lg){
            padding-top: 50px;
        }
        @include mq($until: md){
            padding-top: 0;
            flex-direction: column-reverse;
        }
        &-support {
            display: grid;
            align-items: center;
            column-gap: 40px;
            grid-template-columns: minmax(100px, auto) minmax(300px, auto);
            width: 35%;
            padding-right: 50px;
            align-content: center;
            @include mq($until: xl){
                grid-template-columns: 1fr;
                row-gap: 40px;
                text-align: center;
                justify-items: center;
                padding-top: 60px;
            }
            @include mq($until: lg){
                padding-top: 0;
                padding-right: 25px;
            }
            @include mq($until: md){
                width: 100%;
                padding-bottom: 60px;
                padding-left: 50px;
                padding-right: 50px;
            }
            @include mq($until: sm){
                padding-left: 30px;
                padding-right: 30px;
            }
            @include mq($until: xs){
                padding-left: 20px;
                padding-right: 20px;
            }
            h1 {
                //font-size: rem(34px);
                font-size: 1.7vw;
                font-weight: 700;
                text-transform: uppercase;
                color: var(--paletteColor1);
                margin: 0;
                @include mq($until: xl){
                    font-size: rem(28px);
                }
                @include mq($until: md){
                    font-size: rem(24px);
                }
            }
            img {
                height: auto;
            }
        }
        &-slider {
            padding-left: 50px;
            width: 65%;
            margin-top: -180px;
            @include mq($until: lg){
                padding-left: 25px;
            }
            @include mq($until: md){
                width: 100%;
                padding-left: 50px;
                padding-right: 50px;
                margin-top: -100px;
            }
            @include mq($until: sm){
                padding-left: 30px;
                padding-right: 30px;
            }
            @include mq($until: xs){
                padding-left: 20px;
                padding-right: 20px;
            }
            .catalog-list.slick-slider .slick-track {
                gap: 0;
            }
            .slick-slide img {
                aspect-ratio: 16/9;
                object-fit: cover;
                width: 100vw;
                @include mq($until: xl){
                    aspect-ratio: 4/3;
                }
                @include mq($until: md){
                    aspect-ratio: 16/9;
                }
                @include mq($until: xs){
                    aspect-ratio: 4/3;
                }
            }
            .catalog-card__content {
                position: absolute;
                bottom: 0;
                right: 0;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                padding: 20px 0 20px 150px !important;
                row-gap: 0;
                flex-wrap: wrap;
                @include mq($until: xl){
                    padding: 20px 0 20px 20px !important;
                }
                @include mq($until: xs){
                    position: relative;
                    padding: 0!important;
                    background-color: #000;
                }
                h3 {
                    background-color: #000;
                    color: #fff;
                    padding: 10px 20px;
                    font-size: rem(16px);
                    display: flex;
                    align-items: center;
                    @include mq($until: xs){
                        width: 100%;
                        font-size: 16px;
                        text-align: left;
                    }    
                }
            }
            .catalog-card__footer {
                flex: unset;
                @include mq($until: xs){
                    display: none;
                }
            }
            .slide-content {
                padding-left: 30px;
                padding-right: 0;            
                &[class*='bottom'] {
                    padding-bottom: 30px;
                }
                .content {
                    background-color: var(--paletteColor7);
                    display: flex;
                    column-gap: 10px;
                    row-gap: 10px;
                    align-items: center;
                    p {
                        padding: 10px 20px; 
                        font-weight: 700;
                        font-size: rem(22px);
                    }
                    .button {
                        margin: 0;
                    }
                }
            }
        }
    }
    &__bottom {
        @include mq($until: md){
            padding: 0 50px 60px 50px;
        }
        @include mq($until: sm){
            padding: 0 30px 60px 30px;
        }
        @include mq($until: xs){
            padding: 0 20px 40px 20px;
        }
        h2 {
            font-size: rem(20px);
            margin-top: -40px;
            margin-bottom: 60px;
            @include mq($until: xl){
                margin-top: 40px;
                margin-bottom: 30px;
            }
            @include mq($until: lg){
                text-align: center;
            }
        }
    }
    &__events {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        @include mq($until: xl){
            grid-template-columns: 1fr;
        }
        & > div:last-of-type {
            .events-widget.catalog-list {background-color: #E8E8E8;}
        }
    }
}
