.faq {
    display: flex;
    row-gap: 15px;
    flex-direction: column;
    &.col2 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 10px;
        column-gap: 100px;
        @include mq($until: lg){
            grid-template-columns: 1fr;
        }
    }
    &.col3 {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        row-gap: 10px;
        column-gap: 100px;
        @include mq($until: xl){
            grid-template-columns: 1fr 1fr;
        }
        @include mq($until: lg){
            grid-template-columns: 1fr;
        }
    }
    &--separate {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 80px;
        .faq__question-list {
            display: flex;
            flex-direction: column;
            row-gap: 10px;
        }
        .faq__answear {
            margin: 0;
        }
    }
    .active {
        .faq__question {
            span {
                color: $color-main;
            }
            svg, svg line {
                fill: $color-main;
                stroke: $color-main;
            }
        }
    }
    &__question {
        font-weight: 600;
        font-size: rem(24px);
        cursor: pointer;
        color: $paragraph-color;
        transition: ease 0.3s color;
        &:hover{
            span {
                color: $color-main;
            }
            svg, svg line {
                fill: $color-main;
                stroke: $color-main;
            }
        }
        &:last-of-type {
            margin-bottom: 0;
        }
        .number {
            margin-right: 20px;
            width: 30px;
            height: 30px;
            background: #fff;
            border-radius: 5px;
            display: inline-block;
            text-align: center;
            line-height: 30px;
            min-width: 30px;
        }
        svg {
            transition: ease 0.3s all;
            fill: $paragraph-color;
            stroke: $paragraph-color;
        }
    }
    &__answear {
        display: none;
        margin-top: 10px;
        margin-bottom: 10px;
        /*&.active {
            display: block;
        }*/
    }
    &.numbers {
        &.inline {
            .faq__answear {
                padding-left: 50px;
            }
        }
    }
    &-icons {
        line {
            transition: ease 0.3s all;
        }
        .active .hide {
            opacity: 0;
        }
        .faq__question {
            display: flex;
            //align-items: center;
            svg {
                margin-top: 3px;
                margin-left: 10px;
                width: 20px;
                height: 20px;
                min-width: 20px;
            }
        }
        &.left {
            &.inline {
                &.numbers {
                    .faq__answear {
                        padding-left: 80px;
                    }
                }
                .faq__answear {
                    padding-left: 30px;
                }
            }
            svg {
                order: -1;
                margin-left: 0;
                margin-right: 10px;
            }
        }
        &.space .faq__question {
            justify-content: space-between;
            .question {
                flex: 1;
            }
        }
        &.arrow .active {
            svg {
                transform: rotate(180deg);
                fill: $color-main;
            }
        }
        &.times .active {
            svg {
                transform: rotate(45deg);
            }
        }
    }
}


