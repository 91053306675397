.header__hamburger {
  display: none;
  width: 45px;
  height: 45px;
  position: relative;
  justify-self: flex-end;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  &.button {
    padding: 0;
    display: none;
  }
  &:hover {
    span {
      background-color: var(--headerTxtHover);
    }
  }
  &.button {
    &:hover {
      span {
        background-color: var(--buttonTxtHover);
      }
    }
    span {
      background-color: var(--buttonTxtInitial);
      width: 70%;
      left: 15%;
    }
    &--secondary {
      &:hover {
        span {
          background-color: var(--button2TxtHover);
        }
      }  
      span {
        background-color: var(--button2TxtInitial);
      }
    }
  }
  &--fullscreen {
    display: block;
    z-index: 2;
  }

  @media screen and (max-width: 991px) {
    display: block;
    &.button {
      display: block;
    }  
  }
  div {
    position: relative;
    width: 70%;
    top: 25%;
    left: 50%;
    transform: translateX(-50%);
  }
  span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background-color: var(--headerTxtInitial);
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    &:nth-child(1) {
      top: 12px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }

    &:nth-child(2) {
      top: 21px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }

    &:nth-child(3) {
      top: 30px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }
  }

  &--open {
    &.button {
      span:nth-child(1) {
        left: 25%;
        top: 10px;
      } 
      span:nth-child(3) {
        left: 25%;
        top: 31px;
      } 
    }
    span {
      &:nth-child(1) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        top: 5px;
        left: 7px;
      }

      &:nth-child(2) {
        width: 0;
        opacity: 0;
      }

      &:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
        top: 37px;
        left: 6px;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .mobile-visible .header {
    background-color: #000;
    &.sticky {
      background-color: #fff;
    }
  }
  .header {
    .lang-switcher {
      .label {
        display: none;
      }
      .list {
        display: flex!important;
        position: relative;
        top: unset;
        background-color: unset;
        border: unset;
        li {
          border: unset;
        }
        span:not(.flag) {
          display: none;
        }
      }
    }
    &--absolute:not(.sticky) {
      padding-top: 0;
      align-items: center;
      .header__hamburger {
        span {
          background-color: #fff;
        }
        &--open {
          span {
            background-color: var(--headerTxtHover);
          }
        }
      }
    }
    &--fixed.sticky .header__menuLink {
        color: #fff;
        &:active, &--active, &--open, &:hover {
          color: var(--headerTxtHover);
        }
    }
    &--fixed.sticky .header__menuItem.login {
      position: relative;
      top: unset;
    }
    &__menuItem.login {
      position: relative;
      top: unset;
      right: unset;
      .header__menuLink {
        color: #fff;
        background-color: var(--headerTxtHover);
        padding: 10px 20px;
        border: 0;
        svg path {
          stroke: #fff;
        }
      }
    }
    &__nav {
      overflow: auto;
      position: absolute;
      width: 100%;
      top: 100%;
      right: 0;
      height: 100vh;
      background: var(--paletteColor3);
      z-index: 3;
      transform-origin: 0 0;
      transform: translate(100%, 0);
      transition: transform .5s ease-in-out;
      padding-top: 15px;
      padding-bottom: 15px;
      border-bottom: 100px solid var(--paletteColor3);
      align-items: flex-start;
      flex-direction: column;

      &--visible {
        transform: translate(0, 0);
      }
    }
    &__menuLink {
      color: #fff;
      font-size: rem(24px);
      &[data-hover] {
        display: block;
      }
    }

    &__menuList {
      flex-direction: column;
      padding: 10px 0;
      position: static;
      align-items: flex-start;
      width: 100%;

      & > .header__menuItem {
        display: block;
        width: 100%;

        &--fullscreen {
          display: flex;
          margin: 0 0 25px 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__submenu {
      position: static;
      padding: 10px 0 0 0;
      margin-top: 5px;
      background-color: rgba(255, 255, 255, 0.05);
      border-radius: var(--menuRadius, 0);

      &--fullscreen {
        background-color: transparent;
        justify-content: center;
        align-items: center;
      }

      &--hidden {
        display: none;
      }

      .header__menuItem {
        margin: 0;
      }

      .header__menuLink {
        margin: 0 10px 10px 10px;
        display: inline-block;
      }
    }
  }
}

@media screen and (max-width: 767px) {

}

@media screen and (max-width: 575px) {

}