.prize {
    padding-top: 100px;
    padding-bottom: 100px;
    position: relative;
    background-color: var(--paletteColor7);
    @include mq($until: md){
        padding-top: 60px;
        padding-bottom: 60px;
    }
    @include mq($until: xs){
        padding-top: 30px;
        padding-bottom: 30px;
    }
    &__content {
        .txtimg  {
            display: grid;
            grid-template-columns: auto 35%;
            column-gap: 100px;
            align-items: center;
            @include mq($until: xl){
                &__image img, &__slider img {
                    aspect-ratio: 4/3;
                    object-fit: cover;
                }
            }
            @include mq($until: md){
                grid-template-columns: 1fr;
                &__image img, &__slider img {
                    aspect-ratio: 16/9;
                }
            }
            &__content, &__image, &__slider {
                width: 100%;
            }
        }
        h2 {
            color: var(--paletteColor1);
            text-transform: uppercase;
            margin-bottom: 50px;
            @include mq($until: xl){
                font-size: rem(28px);
                margin-bottom: 30px;
            }
            @include mq($until: md){
                font-size: rem(24px);
            }
        }
        p {
            color: #fff;
            line-height: rem(34px);
            font-size: 15px;
            @include mq($until: xl){
                line-height: rem(24px);
            }
        }
        @include mq($until: xl){
            .txtimg__content .button {
                margin-top: 30px;
            }
        }
    }
    &__carousel {
        margin-top: 40px;
        &-title {
            color: rgba(255, 255, 255, 0.5);
            font-size: rem(21px);
        }
        .slider {
            margin-top: 10px;
            height: 100px;
            .slick-track {
                display: flex;
                align-items: center;
            }
            .slick-list {
                height: 100px!important;
            }
        }
        img {
            max-width: 300px;
            max-height: 80px;
            height: auto;
            width: auto;
            margin: 0 auto;
            opacity: 0.8;
            transition: ease 0.3s all;
            &:hover {
                opacity: 1;
            }
        }
    }
}
