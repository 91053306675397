.offer-widget {
    @include mq($until: lg){
        &.slider.arrows--under-left {
            // margin-bottom: 50px;
        }
    }

    &__single {
        display: grid!important;
        grid-template-columns: auto 35%;
        column-gap: 100px;
        align-items: center;
        align-items: start;
        @include mq($until: lg){
            column-gap: 50px;
            grid-template-columns: 300px auto;
        }
        @include mq($until: md){
            grid-template-columns: 1fr;
        }
    }
    &__single-image {
        img {
            width: 100%;
            height: auto;
            aspect-ratio: 16/9;
            object-fit: cover;
            @include mq($until: lg){
                aspect-ratio: 1/1;
            }
            @include mq($until: md){
                aspect-ratio: 16/9;
            }
        }
        .icon {
            position: absolute;
            z-index: 1;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;
            transition: ease 0.3s all;
            color: #fff;
            &:hover {
                opacity: 1;
            }
            svg {
                width: 24px;
                height: 24px;
                fill: #fff;
            }
        }
    }
    &__single-content {
        display: flex;
        flex-direction: column;
        height: 100%;
        .title {
            padding: 30px 100px 30px 0;
            background-color: var(--bodyBg);
            @include mq($until: md){
                padding: 30px 0;
                text-align: center;
            }
            span {
                color: var(--paletteColor1);
                font-weight: 700;
                font-size: rem(28px);
                opacity: 0;
                transition: opacity 500ms ease 0s;
                @include mq($until: xl){
                    font-size: rem(26px);
                    line-height: rem(26px);
                }
                @include mq($until: lg){
                    font-size: rem(24px);
                    line-height: rem(24px);
                }
            }
            &:after {
                content: '';
                top: 0;
                right: 0;
                width: 100vw;
                height: 100%;
                position: absolute;
                background-color: var(--bodyBg);
                z-index: -1;
                @include mq($until: lg){
                    width: calc(100vw - 300px);
                }
            }
        }
        .excerpt {
            flex: 1;
            background: #000;
            padding: 30px 100px 30px 0;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            row-gap: 20px;
            @include mq($until: md){
                background: unset;
                text-align: center;
                padding: 0;
                align-items: center;
            }
            p {
                color: #fff;
                opacity: 0;
                transition: opacity 500ms ease 0s;
                @include mq($until: md){
                    color: var(--txt);
                }
            }
            .button {
                opacity: 0;
                transition: opacity 500ms ease 0s;
                @include mq($until: md){
                    align-self: unset;
                }
            }
            &:after {
                content: '';
                top: 0;
                right: 0;
                width: 50.03vw;
                height: 100%;
                position: absolute;
                background-color: #000;
                z-index: -1;
                @include mq($until: lg){
                    width: calc(100vw - 300px);
                }
                @include mq($until: md){
                    display: none;
                }
            }
        }
    }
    &:after {
        content: '';
        bottom: -80px;
        right: 0;
        width: 50vw;
        height: 100%;
        position: absolute;
        background-color: #000;
        z-index: -1;
        @include mq($until: lg){
            width: calc(100vw - 300px);
            bottom: -70px;
        }
        @include mq($until: md){
            display: none;
        }
    }
    &.arrows--under-left .slick-arrow.slick-prev {
        left: calc(34vw - 60px);
        @include mq($until: xl){
            left: calc(50vw - 260px);
        }
        @include mq($until: lg){
            left: 140px;
            bottom: -60px;
        }
        @include mq($until: md){
            left: calc(50vw - 100px);
            bottom: -70px;
        }
        @include mq($until: sm){
            left: calc(50vw - 80px);
        }
    }
    &.arrows--under-left .slick-arrow.slick-next {
        left: calc(34vw + 10px);
        @include mq($until: xl){
            left: calc(50vw - 190px);
        }
        @include mq($until: lg){
            left: 210px;
            bottom: -60px;
        }
        @include mq($until: md){
            left: calc(50vw - 50px);
            bottom: -70px;
        }
        @include mq($until: sm){
            left: calc(50vw - 30px);
        }
    }
    .slick-slide {
        transition: unset!important;
        img {
            transition: opacity 500ms ease 0s;
            opacity: 0;
        }
    }
    .slick-current {
        img, .excerpt p, .excerpt .button, .title span {
            opacity: 1;
        }
    }
}
