.txtimg {
	display: flex;
	align-items: center;
	position: relative;
	column-gap: 4vw;
	row-gap: 80px;
	@include mq($until: md) {
		flex-direction: column;
		row-gap: 40px;
	}
	&.img-right {
		flex-direction: row-reverse;
		@include mq($until: md) {
			flex-direction: column;
		}
		.txtimg__content {
			padding-top: 40px;
			padding-bottom: 40px;
			@include mq($until: md) {
				padding-top: 0;
				padding-bottom: 0;
			}
		}
	}
	&.img-left {
		.txtimg__content {
			padding-top: 40px;
			padding-bottom: 40px;
			@include mq($until: md) {
				padding-top: 0;
				padding-bottom: 0;
			}
		}
	}
	&.img-above {
		flex-direction: column;
		.txtimg__content, .txtimg__image, .txtimg__slider {
			width: 100%;
		}
	}
	&.img-under {
		flex-direction: column-reverse;
		@include mq($until: md) {
			flex-direction: column;
		}
		.txtimg__content, .txtimg__image, .txtimg__slider {
			width: 100%;
		}
	}
	&__content, &__image, &__slider {
		width: 50%;
		position: relative;
		@include mq($until: md) {
			width: 100%;
		}
	}
	&__image, &__slider {
		[data-hover] {
			width: 100%;
		}
		img {
			width: 100%;
			height: auto;
		}
  	}
	&__content {
		@include mq($until: md) {
			text-align: center;
			ul {
				text-align: left;
			}
		}
		&.align-center {
			text-align: center;
			ul {
				li {
					text-align: left;
				}
			}
		}
		&.align-left {
			text-align: left;
			@include mq($until: md) {
				text-align: center;
			}
		}
		&.align-right {
			text-align: right;
			@include mq($until: md) {
				text-align: center;
			}
			ul li {
				padding-left: 0;
				padding-right: rem(20px);
				@include mq($until: md) {
					padding-left: rem(20px);
					padding-right: 0;
					text-align: left;
				}
				&:before {
					left: unset;
					right: 0;
					@include mq($until: md) {
						left: 0;
						right: unset;
					}
				}
			}
		}
		ul {
			display: inline-block;
			width: 100%;
			margin: 20px 0 0 0;
		}
		.title {
			margin-bottom: 40px;
		}
		.button {
			margin-top: 40px;
		}
	}
}





.article {
	display: flex;
	align-items: center;
	position: relative;
	margin-bottom: $padding-xl;

	&.reverse {
		flex-direction: row-reverse;
	}

	&__text, &__image, &__slider {
		width: 50%;
		position: relative;
	}


	&__image, &__slider {

		height: 30vw;

		img {
			object-fit: cover;
			width: 100%;
			height: 100%;
		}

  	}

	&__slider {
		position: relative;

		& *:not(.article__slider-arrow) {
			width: 100%;
			height: 100%;
		}

	}

	&__slider-arrow {
		position: absolute;
		bottom: 15%;
		z-index: 1;
		border: none;
		width: 40px;
		height: 40px;
		background-color: #{$color-main}8c;
		border-radius: 50%;
		transition: all .4s;
		background-size: 30%;
		background-repeat: no-repeat;

		&_next {
			right: calc(100px - 40px);
			background-image: url('../../images/next.png');
			background-position: 56% center;
		}
		&_prev {
			right: calc(150px - 40px);
			background-image: url('../../images/prev.png');
			background-position: 44% center;
		}

		&:hover {
			background-color: $color-main;
		}
	}

	&__text {

		&_normal {
			margin-left: 5%;
		}

		&_reverse {
			margin-right: 5%;
		}
		&.center {
			text-align: center;
		}
		&.left {
			text-align: left;
		}
		&.right {
			text-align: right;
		}
	}

	.button {
		margin-top: 20px;
		min-width: 160px;
		display: inline-block;
		width: fit-content;

		/*&-normal {
			margin-right: auto;
		}

		&-reverse {
			margin-left: auto;
		}*/
	}

	@include mq($until: md) {

		flex-direction: column;
		margin-bottom: $padding-lg;

		&.reverse {
			flex-direction: column-reverse;
		}

		&__text, &__image, &__slider {
			width: 100%;
			margin: 0;
		}

		&__image, &__slider {
			height: 60vw;
			margin: 30px 0;
		}

		&__slider-arrow {

			&_next {
				right: calc(50px - 40px);
			}
			&_prev {
				right: calc(100px - 40px);
			}
		}
	}

}
